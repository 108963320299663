/// <reference types="jest" />
import { ɵglobal, ɵɵgetCurrentView, type ɵLContext } from '@angular/core';

import { getZoneUnPatchedApi } from '@cosmos/zone-less';

export function unwrapEventName(eventName: string, modifier: string): string {
  // keyup.enter.silent
  return (
    eventName
      // ['keyup', 'enter', 'silent']
      .split('.')
      // ['keyup', 'enter']
      .filter((part) => part !== modifier)
      // keyup.enter
      .join('.')
  );
}

const originalListenerToken = '__ngUnwrap__';

/**
 * The `wrappedWithPreventDefaultListener` is a function returned by `decoratePreventDefault`.
 * The `decoratePreventDefault` looks as follows:
 * ```js
 * function decoratePreventDefault(eventHandler) {
 *   return event => {
 *     if (event === '__ngUnwrap__') {
 *       return eventHandler;
 *     }
 *
 *     const allowDefaultBehavior = eventHandler(event);
 *     if (allowDefaultBehavior === false) {
 *       event.preventDefault();
 *       event.returnValue = false;
 *     }
 *
 *     return undefined;
 *   };
 * }
 * ```
 *
 * So if we call that function with `__ngUnwrap__` parameter it'll return the function again.
 * The `eventHandler` is a function returned by `wrapListener`, its implementation looks as follows:
 * ```js
 * function wrapListener(tNode, lView, context, listenerFn, wrapWithPreventDefault) {
 *   return function wrapListenerIn_markDirtyAndPreventDefault(event) {
 *     if (event === Function) {
 *       return listenerFn;
 *     }
 *
 *     // `32` is `ManualOnPush`.
 *     if ((lView[FLAGS] & 32) === 0) {
 *       markViewDirty(startView);
 *     }
 *
 *     // ...
 *   }
 * }
 * ```
 * So if we pass in the `Function` constructor as an argument it'll return the original listener function.
 */
export function unwrapOriginalListener(
  // eslint-disable-next-line @typescript-eslint/ban-types
  wrappedWithPreventDefaultListener: Function
): EventListener {
  // Ivy uses '__ngUnwrap__' as a special token that allows us to unwrap the function
  // so that it can be invoked programmatically by `DebugNode.triggerEventHandler`. The debug_node
  // can inspect the listener toString contents for the existence of this special token. Because
  // the token is a string literal, it is ensured to not be modified by compiled code.
  const wrappedWithMarkDirtyAndPreventDefaultListener =
    wrappedWithPreventDefaultListener(originalListenerToken);

  // Ivy uses `Function` as a special token that allows us to unwrap the function
  // so that it can be invoked programmatically by `DebugNode.triggerEventHandler`.
  const originalListener =
    wrappedWithMarkDirtyAndPreventDefaultListener(Function);

  return originalListener;
}

export function ensureMarkForCheckIsCalledIfTheAngularZoneIsReEntered(
  originalListener: EventListener
): void {
  if (typeof jest !== 'undefined') {
    return;
  }

  const methodName = unwrapMethodName(originalListener);

  if (methodName === null) {
    return;
  }

  type LView = ɵLContext['lView'];

  const HOST = 0;
  const lView = ɵɵgetCurrentView() as unknown as LView;

  if (lView === null) {
    return;
  }

  const requestAnimationFrame = getZoneUnPatchedApi('requestAnimationFrame');
  requestAnimationFrame(() => {
    const host = lView[HOST];

    if (host === null) {
      return;
    }

    const component = ɵglobal.ng.getComponent(host);

    if (component === null) {
      return;
    }

    if (
      typeof component[methodName] === 'function' &&
      component[methodName].toString().match(/zone.run/i) !== null &&
      component[methodName].toString().match(/markForCheck/) === null
    ) {
      console.error(
        `This seems like the "${methodName}" on the "${component.constructor.name}" re-enters the Angular zone via "zone.run()", but "markForCheck()" is not called. Call "markForCheck()" within the "zone.run()".`
      );
    }
  });
}

function unwrapMethodName(originalListener: EventListener): null | string {
  // Given the template looks as follows:
  // `<esp-encore-layout (click.silent)="onEncoreLayoutClick()">`

  // The template compiler will generate the below code:
  // function AppRootComponent_Template_esp_encore_layout_click_silent_0_listener() {
  //   return ctx.onEncoreLayoutClick();
  // }

  // If there's no class method invokation and `$event` is accessed within a template, e.g.:
  // `<esp-encore-layout (click.silent)="$event.preventDefault()">`

  // The template compiler will generate this:
  // function AppRootComponent_Template_esp_encore_layout_click_silent_0_listener($event) {
  //   return $event.preventDefault();
  // }

  // We wanna match the `ctx.onEncoreLayoutClick()` to get the `onEncoreLayoutClick` method name.
  const matches = originalListener.toString().match(/ctx\.(.*)\(/);
  // The `matches` will be an array: `['ctx.call(', 'call]`.
  return matches ? matches[1] : null;
}
