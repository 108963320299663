import {
  APP_BOOTSTRAP_LISTENER,
  inject,
  makeEnvironmentProviders,
  NgModuleRef,
  type EnvironmentProviders,
} from '@angular/core';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import type ReconnectingWebSocket from 'reconnecting-websocket';
import {
  BehaviorSubject,
  filter,
  map,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { AsiAuthService, Auth } from '@asi/auth/data-access-auth';
import { WebsocketService } from '@cosmos/util-websocket';
import type { LogoutWebsocketMessage } from '@esp/auth/types';

import { AuthFacade } from '../services';

export function enableMultipleSessionsAutoLogout(): EnvironmentProviders {
  const providers = [
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: () => {
        // Do nothing in Node.js.
        if (global_isServer) {
          return () => {};
        }

        const ngModuleRef = inject(NgModuleRef);
        const actions$ = inject(Actions);
        const authFacade = inject(AuthFacade);
        const authService = inject(AsiAuthService);
        const webSocketService = inject(WebsocketService);

        return () => {
          const destroy$ = new Subject<void>();
          ngModuleRef.onDestroy(() => destroy$.next());

          const isLoggedOut$ = actions$.pipe(ofActionSuccessful(Auth.Logout));

          let webSocket: ReconnectingWebSocket | null = null;

          // Get a tokenized URI where we will use to subscribe using websockets
          const tokenizerUri$ = authService
            .websocketURI$()
            .pipe(filter(Boolean));

          const userSource$ = authFacade.profile$.pipe(
            map((user) => user),
            filter(Boolean)
          );

          const isConnected$ = new BehaviorSubject<boolean>(false);

          userSource$
            .pipe(
              switchMap(() => tokenizerUri$),
              switchMap((uri) => webSocketService.connect$(uri)),
              tap((socket) => (webSocket = socket)),
              takeUntil(destroy$)
            )
            .subscribe(() => isConnected$.next(true));

          isConnected$
            .pipe(
              filter(Boolean),
              switchMap(() =>
                webSocketService.messages$<LogoutWebsocketMessage>(webSocket!)
              ),
              takeUntil(destroy$)
            )
            .subscribe((message) => {
              if (
                message?.data?.username &&
                ((authFacade.user?.UserName &&
                  message.data.username.toLowerCase() ===
                    authFacade.user.UserName.toLowerCase()) ||
                  (authFacade.user?.Email &&
                    message.data.username.toLowerCase() ===
                      authFacade.user.Email.toLowerCase()))
              ) {
                authFacade.logout(undefined, true);
              }
            });

          // When the user logs out, disconnect from the websocket
          isLoggedOut$
            .pipe(takeUntil(destroy$))
            .subscribe(() => webSocketService.disconnect(webSocket));
        };
      },
    },
  ];

  return makeEnvironmentProviders(providers);
}
