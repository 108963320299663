import {
  HttpHandler,
  HttpRequest,
  type HttpEvent,
  type HttpInterceptor,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import type { Observable } from 'rxjs';

import { AppSettingsQueries } from '@cosmos/data-access-app-settings';
import { ORDERS_SETTINGS_KEY, type OrdersSettings } from '@esp/orders/types';

const documentsNewStyleEnabledQuery =
  AppSettingsQueries.getAppFeatureSettingPropValueFor<
    OrdersSettings,
    'documentsNewStyleEnabled'
  >(ORDERS_SETTINGS_KEY, 'documentsNewStyleEnabled');

/*
  This interceptor is responsible for switching to the new style of order documents when this option is enabled.
  It's a temporary solution that allows testing of the new documents in development environments.
  The interceptor is based on the selection made in the EspDocumentStyleSelectComponent, which can be found in the dev toolbar.
 */
@Injectable()
export class OrderDocumentsNewStyleInterceptor implements HttpInterceptor {
  private _store = inject(Store);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(this._shouldIntercept() ? this._intercept(req) : req);
  }

  private _intercept(req: HttpRequest<unknown>): HttpRequest<unknown> {
    return this._interceptPurchaseOrder(req);
  }

  private _interceptPurchaseOrder(
    req: HttpRequest<unknown>
  ): HttpRequest<unknown> {
    const valueToReplace = 'po';
    const newValue = 'po-new';
    const url = req.url.toString();
    type DocumentBody = { DocumentType: string };

    if (url.includes(`documentType=${valueToReplace}`)) {
      return req.clone({
        url: url.replace(
          `documentType=${valueToReplace}`,
          `documentType=${newValue}`
        ),
      });
    }
    if (
      req.body &&
      (req.body as DocumentBody).DocumentType === valueToReplace
    ) {
      return req.clone({
        body: {
          ...(req.body as object),
          DocumentType: newValue,
        },
      });
    }

    return req;
  }

  private _shouldIntercept(): boolean {
    return this._store.selectSnapshot(documentsNewStyleEnabledQuery);
  }
}
