import { Injectable } from '@angular/core';
import { Action, State, type StateContext } from '@ngxs/store';
import { catchError, EMPTY, tap } from 'rxjs';

import { setEntityStateItem, type EntityStateModel } from '@cosmos/state';
import type { ModelWithLoadingStatus } from '@cosmos/types-common';
import { ToastActions } from '@cosmos/types-notification-and-toast';
import { FilesService } from '@esp/common/data-access-files';
import { SearchCriteria } from '@esp/common/types';
import { CompaniesService } from '@esp/companies/data-access-api';
import type { Artwork } from '@esp/orders/types';

import { CompaniesDesignsActions } from '../../actions';

import { TOAST_MESSAGES } from './toast-messages';

export interface CompaniesDesignsStateModel
  extends ModelWithLoadingStatus,
    EntityStateModel<Artwork> {
  criteria: SearchCriteria | null;
  blobFile?: Blob;
}

type ThisStateModel = CompaniesDesignsStateModel;
type ThisStateContext = StateContext<ThisStateModel>;

@State<CompaniesDesignsStateModel>({
  name: 'companiesDesigns',
  defaults: {
    criteria: new SearchCriteria(),
    items: {},
    itemIds: [],
  },
})
@Injectable()
export class CompaniesDesignsState {
  constructor(
    private readonly _companiesService: CompaniesService,
    private readonly _fileService: FilesService
  ) {}

  @Action(CompaniesDesignsActions.UpdateDesign)
  editDesign(
    ctx: ThisStateContext,
    action: CompaniesDesignsActions.UpdateDesign
  ) {
    return this._companiesService
      .updateDesign(action.design)
      .pipe(
        tap((design) => ctx.setState(setEntityStateItem(design.Id, design)))
      );
  }

  @Action(CompaniesDesignsActions.Download)
  downloadDesign(
    ctx: ThisStateContext,
    action: CompaniesDesignsActions.Download
  ) {
    // return this._fileService
    //   .downloadFile({ MediaId: mediaObjectId })
    //   .pipe(tap((arg) => ctx.patchState({ blobFile: arg })));
  }

  @Action(CompaniesDesignsActions.Delete)
  deleteDesign(ctx: ThisStateContext, action: CompaniesDesignsActions.Delete) {
    return this._companiesService.deleteDesign(action.designId).pipe(
      tap(() =>
        ctx.dispatch(new ToastActions.Show(TOAST_MESSAGES.DESIGN_DELETED()))
      ),
      catchError(() => {
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.DESIGN_NOT_DELETED())
        );
        return EMPTY;
      })
    );
  }
}
