import {
  importProvidersFrom,
  type EnvironmentProviders,
  type Provider,
} from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

export const provideAppUi = (): (Provider | EnvironmentProviders)[] => [
  importProvidersFrom([
    NgxSkeletonLoaderModule.forRoot({
      animation: 'progress-dark',
      loadingText: 'loading...',
      theme: {
        extendsFromRoot: true,
        background: 'var(--base-200)',
      },
    }),
  ]),
];
