<esp-encore-layout
  *cosLet="vm$ as vm"
  [showHeader]="!vm.routerData.hideGlobalHeader"
  [showFooter]="!vm.routerData.hideGlobalFooter"
>
  @if (vm.renderHeaderAndFooter) {
    <ng-container ngProjectAs="cos-global-header">
      <esp-feature-global-header
        [showGlobalSearch]="!vm.routerData.hideGlobalSearch"
      />
    </ng-container>
  }
  @if (vm.renderHeaderAndFooter) {
    <esp-feature-global-footer />
  }
</esp-encore-layout>
