import { provideWhiteLabelDataAccess } from 'esp/white-label/data-access';

import { importProvidersFrom } from '@angular/core';

import { provideEspActivitiesDataAccessStores } from '@esp/activities/data-access-activities';
import { EspAutocompleteModule } from '@esp/autocomplete/data-access-autocomplete';
import { EspCollectionsModule } from '@esp/collections/data-access-collections';
import { EspCompaniesDataAccessCompaniesModule } from '@esp/companies/data-access-companies';
import { EspContactsDataAccessContactsModule } from '@esp/contacts/data-access-contacts';
import { EspEmailsDataAccessModule } from '@esp/emails/data-access-emails';
import { EspLookupTypesModule } from '@esp/lookup/data-access-lookup';
import { EspNotesModule } from '@esp/notes/data-access-notes';
import { EspNotificationsDataAccessNotificationsModule } from '@esp/notifications/data-access-notifications';
import { EspPreferredSuppliersDataAccessModule } from '@esp/preferred-suppliers/data-access-preferred-suppliers';
import { EspPresentationsDataAccessPresentationsModule } from '@esp/presentations/data-access-presentations';
import { EspProjectsDataAccessProjectItemsModule } from '@esp/projects/data-access-project-items';
import { EspProjectsDataAccessStoreModule } from '@esp/projects/data-access/store';
import { EspSettingsDataAccessSettingsModule } from '@esp/settings/data-access-settings';
import { provideEspSuppliersDataAccessInterceptor } from '@esp/suppliers/data-access-suppliers';
import { provideEspTasksDataAccessStores } from '@esp/tasks/data-access/store';
import { EspWebsitesDataAccessWebsitesModule } from '@esp/websites/data-access-websites';
import { SmartlinkProductsDataAccessProductInventoryModule } from '@smartlink/products/data-access-product-inventory';

export const provideAppDataAccess = () => [
  provideWhiteLabelDataAccess(),
  provideEspTasksDataAccessStores(),
  provideEspActivitiesDataAccessStores(),
  provideEspSuppliersDataAccessInterceptor(),
  importProvidersFrom([
    EspAutocompleteModule,
    EspCollectionsModule.forRoot(),
    EspCompaniesDataAccessCompaniesModule,
    EspNotesModule.forRoot(),
    EspLookupTypesModule,
    EspProjectsDataAccessStoreModule,
    EspProjectsDataAccessProjectItemsModule,
    EspPreferredSuppliersDataAccessModule,
    EspPresentationsDataAccessPresentationsModule,
    EspWebsitesDataAccessWebsitesModule,
    EspNotificationsDataAccessNotificationsModule,
    EspEmailsDataAccessModule,
    EspSettingsDataAccessSettingsModule,
    EspContactsDataAccessContactsModule,
    SmartlinkProductsDataAccessProductInventoryModule,
  ]),
];
