import { NgModule } from '@angular/core';

import { CosGlobalTabsComponent } from './global-tabs.component';

const components = [CosGlobalTabsComponent];

@NgModule({
  imports: [...components],
  exports: [...components],
})
export class CosGlobalTabsModule {}
