import { Injectable } from '@angular/core';

import { LocalState } from '@cosmos/state';
import { syncOrdersSetting } from '@esp/orders/data-access-orders';

@Injectable()
export class DocumentStyleSelectLocalState extends LocalState<DocumentStyleSelectLocalState> {
  documentsNewStyleEnabled = syncOrdersSetting(
    'documentsNewStyleEnabled',
    false
  );
}
