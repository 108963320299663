import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AbstractLayoutComponent } from '@cosmos/layout';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'esp-encore-layout',
  templateUrl: './encore-layout.component.html',
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class EncoreLayoutComponent extends AbstractLayoutComponent {
  @Input() showHeader = true;
  @Input() showFooter = true;
}
