import { NgModule, type ModuleWithProviders } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { RouterHistoryState } from '../lib/state';

@NgModule()
export class EspRouterModule {
  static forRoot(): ModuleWithProviders<RootEspRouterModule> {
    return { ngModule: RootEspRouterModule };
  }
}

@NgModule({
  imports: [NgxsModule.forFeature([RouterHistoryState])],
})
export class RootEspRouterModule {}
