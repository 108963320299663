import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { EmailDetailsState, EmailsSearchState } from './states';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([EmailsSearchState, EmailDetailsState]),
  ],
})
export class EspEmailsDataAccessModule {}
