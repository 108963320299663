import { ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, Router, Scroll } from '@angular/router';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { BehaviorSubject, filter, map, race, switchMap, tap } from 'rxjs';

import { RouteSnapshotService } from '@cosmos/router';
import { injectAppDestroyRef } from '@cosmos/util-app-destroy-ref';

/**
   * Let's assume the following user flow:
   *  1. A user searches for products and gets the results in the "product search results" page
   *  2. Scrolls to a desired product, clicks on a product card and navigates to "product details" page
   *  3. Clicks the back button and goes to the "product search results" page.
   *
   * This service is responsible to restore to the scrolling position of step no.2
   *
   * We can achieve the scrolling restoration using either a Directive or a Dispatched Action.
   *
   * Example using a **Directive**:
   *  We use the directive "cosRestoreScrolling" just once in the page where we need to restore the scrolling.
   *  Make sure that it is registered on an element that appears when the search results exist. It could be the results container.
   *
   * Example using a **Dispatched Action**:
   * In the routing configuration we should add in the data the object "restoreScrollPosition.action", providing the action that gets the search results
   *
        restoreScrollPosition: {
            action: DecoratorSearchActions.Search
        }
   */
@Injectable({ providedIn: 'root' })
export class CosScrollPositionRestoration {
  readonly elementIsVisible$ = new BehaviorSubject<boolean>(false);

  private readonly _appDestroyRef = injectAppDestroyRef();

  constructor(
    private readonly _viewportScroller: ViewportScroller,
    private readonly _routeSnapshot: RouteSnapshotService,
    private readonly _router: Router,
    private readonly _actions$: Actions
  ) {
    this._init();
  }

  private _init() {
    // Returns "true" when the directive "cosRestoreScrolling" appears to the page
    const elementStrategy$ = this.elementIsVisible$.pipe(filter(Boolean));

    // Returns "true" when the NGXS action has been successfully dispatched
    const actionStrategy$ = this._routeSnapshot.snapshot$.pipe(
      filter(Boolean),
      map((route: ActivatedRouteSnapshot) => route.data),
      map((data: any) => data?.restoreScrollPosition?.action),
      filter(Boolean),
      switchMap((action) => {
        return this._actions$.pipe(
          ofActionSuccessful(action),
          switchMap(() => Promise.resolve().then(() => true))
        );
      })
    );

    // We use either the "Action" or the "Element" strategy. Even if both of them are available, we use one of them
    const scrollingStrategy$ = race(actionStrategy$, elementStrategy$);

    // The Angular's scroll event returns the scrolling position in the format [number, number].
    // We scroll to the returned position, or to top otherwise
    const scrollingPositionEvent$ = this._router.events.pipe(
      filter((event: any) => event instanceof Scroll),
      map((event) => event?.position)
    );

    scrollingPositionEvent$
      .pipe(
        switchMap((position) => {
          return scrollingStrategy$.pipe(
            tap(() => {
              if (position) {
                this._viewportScroller.scrollToPosition(position);
              }
            })
          );
        }),
        takeUntilDestroyed(this._appDestroyRef)
      )
      .subscribe({
        next: () => {
          // clean up the state
          this.elementIsVisible$.next(false);
        },
      });
  }
}
