/* eslint-disable no-restricted-globals */
// Note: we also have the `LazyLoadingScriptService` but the DI is not accessible yet
// when polyfills are loading (they're loaded before the app is bootstrapped).
export function loadScript(src: string) {
  return new Promise<void>((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', () => {
      resolve();
    });
    document.head.appendChild(script);
  });
}
