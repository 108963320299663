import { CommonModule } from '@angular/common';
import {
  APP_INITIALIZER,
  ChangeDetectionStrategy,
  Component,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { WINDOW } from '@ng-web-apis/common';

import { LocalStorageService } from '@cosmos/data-access-storage';
import {
  CosFeatureFlagsToolbarComponent,
  CosFeatureFlagsToolbarControlsSet,
} from '@cosmos/feature-flags-toolbar';

@Component({
  selector: 'cos-feature-flags-select',
  templateUrl: './feature-flags-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class CosFeatureFlagsSelectComponent {
  private readonly _localStorageService = inject(LocalStorageService);
  private readonly _window = inject(WINDOW);

  constructor(
    readonly featureFlagsToolbarComponent: CosFeatureFlagsToolbarComponent
  ) {}

  changeFeatureFlagMode(): void {
    this._localStorageService.setItem(
      'featureFlagsSet',
      this.featureFlagsToolbarComponent.featureFlag
    );
    this._window.location.reload();
  }
}

export function withFlagsSelect() {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        const controls = inject(CosFeatureFlagsToolbarControlsSet);
        return () => controls.add(CosFeatureFlagsSelectComponent);
      },
    },
  ]);
}
