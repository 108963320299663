import { WhiteLabelQueries } from 'esp/white-label/data-access';

import { Injectable } from '@angular/core';

import { fromSelector, LocalState } from '@cosmos/state';

@Injectable()
export class GlobalHeaderLocalState extends LocalState<GlobalHeaderLocalState> {
  readonly whiteLabelSettings = fromSelector(WhiteLabelQueries.getSettings);
  readonly isWhiteLabelNameSet = fromSelector(
    WhiteLabelQueries.isWhiteLabelNameSet
  );
}
