import { inject, Injectable, type OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Meta, Title } from '@angular/platform-browser';
import { ActivationStart, Router } from '@angular/router';
import { isEqual } from 'lodash-es';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
  timeout,
  type Subscription,
} from 'rxjs';

import type { DefaultRouteData, TypedRoute } from '@cosmos/router';
import { CosmosTranslocoService } from '@cosmos/util-translations';

import { META_SETTINGS, type MetaSettings } from './symbols';

const enum OpenGraphTag {
  Title = 'og:title',
  Description = 'og:description',
  Image = 'og:image',
}

type TitleConfig = {
  applicationName?: string;
  title?: string;
  interpolationParams?: Record<string, unknown>;
  separator?: string;
};

@Injectable({ providedIn: 'root' })
export class MetaService implements OnDestroy {
  readonly _titleChanged = new Subject<void>();

  private _router = inject(Router);
  private _meta = inject(Meta);
  private _title = inject(Title);
  private _settings: Observable<MetaSettings> = inject(META_SETTINGS);
  private _subscription!: Subscription;
  private readonly _translocoService = inject(CosmosTranslocoService);

  private readonly _currentTitle = new BehaviorSubject<TitleConfig | null>(
    null
  );

  constructor() {
    this._setupRouterEventsListener();
    this._setupTitleListener();
  }

  ngOnDestroy(): void {
    // Do not leak in unit tests.
    this._subscription.unsubscribe();
  }

  /**
   * @param title either a string or a translation key for the page title
   * @param interpolationParams params to be used for translation of the title if it's a translation key
   */
  async updateTitle(
    title: string,
    interpolationParams?: TitleConfig['interpolationParams']
  ) {
    let defaultConfig: MetaSettings | undefined;

    try {
      // timeout the config observable
      // it's usually provided as a BehaviorSubject and should resolve instantly,
      // this check only exists in case something is changed
      const settings$ = this._settings.pipe(timeout(100));
      defaultConfig = await firstValueFrom(settings$);
    } catch (error) {
      if (!global_isRealProduction) {
        console.error('Retrieving default config has timed out');
      }
    }

    this._currentTitle.next({
      title,
      interpolationParams,
      applicationName: defaultConfig?.applicationName,
      separator: defaultConfig?.pageTitleSeparator,
    });
  }

  private _setupTitleListener(): void {
    this._currentTitle
      .pipe(
        filter((v): v is TitleConfig => !!v),
        distinctUntilChanged((a, b) => isEqual(a, b)),
        switchMap((cfg) => {
          const scope = this._translocoService._deduceScopeFromKey(cfg.title);
          if (!scope) {
            return of(this._buildTitle(cfg));
          }
          let prev: string;
          return this._translocoService.getLangChanges$([scope]).pipe(
            // if this is a first translation (!prevTranslated), then proceed
            // if we're getting here after language has been changed, ensure title is not changed elsewhere
            filter(() => !prev || prev === this._title.getTitle()),
            map(() =>
              this._translocoService.translate(
                cfg.title!,
                cfg.interpolationParams
              )
            ),
            map((translatedTitle) =>
              this._buildTitle({ ...cfg, title: translatedTitle })
            ),
            tap((title) => (prev = title))
          );
        }),
        takeUntilDestroyed()
      )
      .subscribe((title) => {
        this._title.setTitle(title);
        this._meta.updateTag({
          property: OpenGraphTag.Title,
          content: title,
        });
        this._titleChanged.next();
      });
  }

  updateDescription(description: string | null | undefined): void {
    this._setMetaTag('description', description);
    this._setMetaTag(OpenGraphTag.Description, description, 'property');
  }

  updateImage(imageUrl: string | null | undefined): void {
    this._setMetaTag('image', imageUrl);
    this._setMetaTag(OpenGraphTag.Image, imageUrl, 'property');
  }

  updateKeywords(keywords: string[] | null | undefined): void {
    this._setMetaTag('keywords', keywords);
  }

  updateRobots(robots: string | null | undefined): void {
    this._setMetaTag('robots', robots);
  }

  private _setupRouterEventsListener(): void {
    const router$ = this._router.events.pipe(
      filter(
        (event): event is ActivationStart => event instanceof ActivationStart
      )
    );

    this._subscription = combineLatest([router$, this._settings]).subscribe(
      ([event, settings]) => {
        const meta = (event.snapshot.data as TypedRoute['data'])?.meta;

        const newTitle = this._getTitle(meta, settings);

        this._currentTitle.next(newTitle);

        this.updateDescription(
          meta?.description ?? settings.defaults.description
        );
        this.updateImage(meta?.image ?? settings.defaults.image);
        this.updateKeywords(meta?.keywords ?? settings.defaults.keywords);
        this.updateRobots(meta?.robots ?? settings.defaults.robots);
      }
    );
  }

  private _buildTitle(cfg: TitleConfig): string {
    if (cfg.title && cfg.applicationName) {
      return [cfg.title, cfg.separator, cfg.applicationName]
        .filter(Boolean)
        .join('');
    }
    if (cfg.title && !cfg.applicationName) {
      return cfg.title;
    }
    return cfg.applicationName!;
  }

  private _getTitle(
    data: DefaultRouteData['meta'] | undefined,
    settings: MetaSettings
  ): TitleConfig {
    return data?.title
      ? {
          title: data.title,
          interpolationParams: data.interpolationParams,
          separator: settings.pageTitleSeparator,
          applicationName: settings.applicationName,
        }
      : { applicationName: settings.applicationName };
  }

  private _setMetaTag(
    metaPropertyValue: string,
    metaPropertyContent: string | string[] | null | undefined,
    metaPropertyName = 'name'
  ): void {
    const content = Array.isArray(metaPropertyContent)
      ? metaPropertyContent.join(', ')
      : metaPropertyContent;

    if (typeof content === 'undefined' || content === null) {
      this._meta.removeTag(`${metaPropertyName}="${metaPropertyValue}"`);
    } else {
      const isTagAdded = !!this._meta.getTag(
        `${metaPropertyName}="${metaPropertyValue}"`
      );
      const tagPayload = {
        [metaPropertyName]: metaPropertyValue,
        content,
      };

      if (isTagAdded) {
        this._meta.updateTag(tagPayload);
      } else {
        this._meta.addTag(tagPayload);
      }
    }
  }
}
