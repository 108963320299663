import {
  APP_INITIALIZER,
  ChangeDetectionStrategy,
  Component,
  inject,
  InjectionToken,
  makeEnvironmentProviders,
} from '@angular/core';

import { CosFeatureFlagsToolbarControlsSet } from '@cosmos/feature-flags-toolbar';
import { DateAgoPipe } from '@cosmos/util-date-time-pipes';
import { CosDatePipe } from '@cosmos/util-i18n-dates';

export interface GitInformation {
  url: string;
}

export const GIT_INFORMATION = new InjectionToken<GitInformation>(
  ngDevMode ? 'GIT_INFORMATION' : '',
  {
    providedIn: 'root',
    factory: () => ({ url: '' }),
  }
);

@Component({
  selector: 'cos-feature-flags-toolbar-information',
  templateUrl: './feature-flags-toolbar-information.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DateAgoPipe, CosDatePipe],
})
export class CosFeatureFlagsToolbarInformationComponent {
  readonly gitInformation = inject(GIT_INFORMATION);

  readonly gitSha = process.env['GIT_SHA'];
  readonly gitBranch = process.env['GIT_BRANCH'];
  readonly buildDate = new Date(process.env['BUILD_DATE']!);
}

export function withFlagsToolbarInformation(gitInformation: GitInformation) {
  return makeEnvironmentProviders([
    { provide: GIT_INFORMATION, useValue: gitInformation },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        const controls = inject(CosFeatureFlagsToolbarControlsSet);
        return () => controls.add(CosFeatureFlagsToolbarInformationComponent);
      },
    },
  ]);
}
