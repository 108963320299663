<div class="global-footer">
  <div
    class="flex flex-col"
    [class]="{ 'grid-layout-1': !state.isMobileOrTablet }"
  >
    @if (state.vm) {
      @defer (when state.vm.navItems.length) {
        <esp-column-layout-footer
          [navItems]="state.vm.navItems"
          [isMobileOrTablet]="state.isMobileOrTablet"
          
        />
      }

      @defer (when !state.vm.navItems.length) {
        <esp-footer
          
          supportSubject="ESP+ Support Inquiry"
          feedbackSubject="ESP+ Feedback"
        />
      }
    }
  </div>

  @if (state.hasWhiteLabelName && state.vm && state.vm.logoUrl) {
    <div class="bg-base-100 mt-2">
      <div class="grid-layout-1 flex justify-between items-center py-2 px-4">
        <div>
          <img
            [src]="state.vm.logoUrl"
            height="40px"
            
          />
        </div>
      </div>
    </div>
  }
</div>
