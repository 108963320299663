import { Injectable } from '@angular/core';

import { linkDownload } from '@cosmos/util-common';

import { DownloadService } from './download.service';

@Injectable()
export class WebDownloadService extends DownloadService {
  async fromImageUrl(props: { url: string; fileName?: string }) {
    if (!props.fileName) throw new Error('File name is required');
    /**
     * We need to use the linkDownload. Instead of using xhr.
     * Because with XHR we are currently facing CORS issues with.
     * Some images. So inorder to bypass the CORS. We will use linkDownload.
     * And open the images in new Tab.
     */
    return linkDownload(props.url, props.fileName);
  }
}
