import { CommonModule } from '@angular/common';
import {
  APP_INITIALIZER,
  ChangeDetectionStrategy,
  Component,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ThemesService } from '@cosmos/data-access-themes';
import { CosFeatureFlagsToolbarControlsSet } from '@cosmos/feature-flags-toolbar';
import { CosTheme } from '@cosmos/types-common';

@Component({
  selector: 'cos-feature-flags-theme',
  templateUrl: './feature-flags-theme.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class CosFeatureFlagsThemeComponent {
  private readonly _themesService = inject(ThemesService);
  readonly themeOptions = Object.values(CosTheme);

  readonly current$ = this._themesService.current$;

  selectChanged(selected: CosTheme): void {
    this._themesService.apply(selected);
  }
}

export function withThemeSelect() {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        const controls = inject(CosFeatureFlagsToolbarControlsSet);
        return () => controls.add(CosFeatureFlagsThemeComponent);
      },
    },
  ]);
}
