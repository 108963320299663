import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { LocalizationSettingsService } from './services';
import {
  AccountingIntegrationsState,
  CompanyProfileState,
  CurrencyConversionRatesState,
  DesignSettingsState,
  DocumentRequirementsSettingsState,
  IntegrationsSettingsState,
  LinkingCompaniesSearchState,
  NotificationsState,
  OrderSettingsState,
  PaymentProvidersSettingsState,
  QuickBooksIntegrationState,
  SecuritySettingsState,
  SettingsState,
  TaxesState,
  TeamsSearchState,
  TrackingSettingsState,
  UserSearchState,
  UserState,
} from './states';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      SettingsState,
      DesignSettingsState,
      SecuritySettingsState,
      OrderSettingsState,
      CompanyProfileState,
      TrackingSettingsState,
      NotificationsState,
      IntegrationsSettingsState,
      AccountingIntegrationsState,
      LinkingCompaniesSearchState,
      QuickBooksIntegrationState,
      TaxesState,
      UserSearchState,
      UserState,
      TeamsSearchState,
      CurrencyConversionRatesState,
      PaymentProvidersSettingsState,
      DocumentRequirementsSettingsState,
    ]),
  ],
})
export class EspSettingsDataAccessSettingsModule {
  // Note: the constructor is responsible to create the class instance
  constructor(_localizationSettingsService: LocalizationSettingsService) {}
}
