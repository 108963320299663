export interface ZoneFlagsLocalConfig {
  disableXhr?: boolean;
  disableWebsocketEvents?: boolean;
}

export function setupZoneFlags(
  zoneFlagsLocalConfig?: ZoneFlagsLocalConfig
): void {
  // `ZoneGlobalConfigurations` is from `zone.js/zone.d.ts`.
  // eslint-disable-next-line no-restricted-globals
  const config = window as ZoneGlobalConfigurations;

  config.__zone_symbol__UNPATCHED_EVENTS = [];

  // Cast to `any` because `__Zone_ignore_on_properties` is not defined.
  // The PR is already there to fix it.
  config.__Zone_ignore_on_properties = [];

  disableWindowPatch(config);

  disableFileReaderPatch(config);

  disableXhrEventsPatch(config);

  if (zoneFlagsLocalConfig?.disableXhr) {
    config.__Zone_disable_XHR = true;
  }

  disableImageElementPatch(config);

  if (!zoneFlagsLocalConfig?.disableWebsocketEvents) {
    // Always call native API immediately for these events, for instance:
    // const socket = new WebSocket(...)
    // socket.addEventListener('message', () => console.log(Zone.current))
    // The `Zone.current` will never be Angular because `message` event is NOT patched.
    const websocketEvents = ['close', 'message', 'open'];
    config.__zone_symbol__UNPATCHED_EVENTS.push(...websocketEvents);
  }
}

function disableWindowPatch(config: ZoneGlobalConfigurations): void {
  // Do not patch `window.onerror` (used by LogRocket).
  config.__Zone_ignore_on_properties!.push({
    // eslint-disable-next-line no-restricted-globals
    target: window,
    ignoreProperties: ['error'],
  });
}

function disableFileReaderPatch(config: ZoneGlobalConfigurations): void {
  // Do not patch `FileReader`.
  config.__Zone_disable_FileReader = true;

  // Do not patch `FileReader.prototype.onload` (used by LogRocket).
  config.__Zone_ignore_on_properties!.push({
    target: FileReader.prototype,
    ignoreProperties: ['load'],
  });
}

function disableXhrEventsPatch(config: ZoneGlobalConfigurations): void {
  const xhrEvents = [
    'load',
    'readystatechange',
    'timeout',
    'progress',
    'error',
  ];

  // Do not patch `XMLHttpRequest.prototype.onload` (used by Rollbar).
  config.__Zone_ignore_on_properties!.push(
    { target: XMLHttpRequest.prototype, ignoreProperties: xhrEvents },
    { target: XMLHttpRequestEventTarget.prototype, ignoreProperties: xhrEvents }
  );
}

function disableImageElementPatch(config: ZoneGlobalConfigurations): void {
  // Do not patch `HTMLImageElement.prototype.onload` (used by Fabric).
  config.__Zone_ignore_on_properties!.push({
    target: HTMLImageElement.prototype,
    ignoreProperties: ['load', 'error'],
  });
}
