<div class="icon">
  <i class="fa fa-file-lines"></i>
</div>
<div class="label">
  <cos-toggle
    labelPosition="after"
    size="sm"
    [formControl]="documentsNewStyleEnabled"
    >Documents new style
  </cos-toggle>
</div>
