import {
  ENVIRONMENT_INITIALIZER,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';

import {
  SetDescription,
  SetImage,
  SetKeywords,
  SetRobots,
  SetTitle,
} from './meta.actions';
import { MetaService } from './meta.service';
import { META_SETTINGS, type MetaSettings } from './symbols';

export function provideMeta(settings: MetaSettings) {
  return makeEnvironmentProviders([
    {
      provide: META_SETTINGS,
      useValue: new BehaviorSubject<MetaSettings>(settings),
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: setupActionsListener,
    },
  ]);
}

function setupActionsListener(): void {
  const actions$ = inject(Actions);
  const meta = inject(MetaService);

  actions$
    .pipe(
      ofActionDispatched(
        SetTitle,
        SetDescription,
        SetImage,
        SetKeywords,
        SetRobots
      )
    )
    .subscribe((action) => {
      if (action instanceof SetTitle) {
        meta.updateTitle(action.title, action.interpolationParams);
      } else if (action instanceof SetDescription) {
        meta.updateDescription(action.description);
      } else if (action instanceof SetImage) {
        meta.updateImage(action.imageUrl);
      } else if (action instanceof SetKeywords) {
        meta.updateKeywords(action.keywords);
      } else if (action instanceof SetRobots) {
        meta.updateRobots(action.robots);
      }
    });
}
