import { importProvidersFrom, type EnvironmentProviders } from '@angular/core';
import {
  NgxsRouterPluginModule,
  type NavigationActionTiming,
} from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

import { environment } from '../environments/environment';

const NavigationActionTiming__PostActivation: NavigationActionTiming.PostActivation = 2;

export const provideAppNgxs = (): EnvironmentProviders[] => [
  importProvidersFrom([
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
      selectorOptions: {
        /*
        For now we will still suppress errors in production, but not in dev mode.
        These should be resolved in dev mode, and then eventually we can flip this flag to be `false` in production too.
        */
        suppressErrors: environment.production,
      },
      compatibility: {
        strictContentSecurityPolicy: true,
      },
    }),
    NgxsRouterPluginModule.forRoot({
      navigationActionTiming: NavigationActionTiming__PostActivation,
    }),

    NgxsStoragePluginModule.forRoot({
      namespace: 'esp',
      keys: [
        'auth',
        'appSettings',
        // `companyProfile` must be persisted because there may be delays
        // in receiving GET company profile responses. The company profile
        // data is used in various places, especially when sharing entities
        // through email, where we need to prefill the company information.
        'companyProfile',
      ],
    }),
  ]),
];
