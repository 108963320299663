import type { CosGlobalTabsNavigationItem } from '@cosmos/types-layout';
import { LanguageScope } from '@cosmos/util-translations';
import {
  allowedMobileMenuPropertiesToOverride,
  type MobileMenuItemOverrideSettings,
  type MobileMenuItemsKeyValue,
  type MobileMenuTypes,
} from '@esp/misc/types-menu-items';

import { constructMenu, safeOverrideProperties } from './utils';

const feedbackMenuItem = (
  overrides?: Partial<MobileMenuItemOverrideSettings>
): CosGlobalTabsNavigationItem => {
  return {
    id: 'feedback',
    title: 'cosmos.components.global-header.feedback',
    icon: 'fa fa-paper-plane',
    classes: '!hidden xl:!flex',
    hideTitle: false,
    languageScope: LanguageScope.Cosmos,
    url: ['mailto:feedback@asicentral.com?subject=ESP+%20Feedback'],
    externalUrl: true,
    featureFlags: { matches: ['feedBack'] },
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedMobileMenuPropertiesToOverride,
    }),
  };
};

const collectionsMenuItem = (
  overrides?: Partial<MobileMenuItemOverrideSettings>
): CosGlobalTabsNavigationItem => {
  return {
    id: 'collections',
    title: 'cosmos.components.global-header.collections',
    icon: 'fa fa-archive',
    hideTitle: false,
    languageScope: LanguageScope.Cosmos,
    url: ['/collections'],
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedMobileMenuPropertiesToOverride,
    }),
  };
};

const projectsMenuItem = (
  overrides?: Partial<MobileMenuItemOverrideSettings>
): CosGlobalTabsNavigationItem => {
  return {
    id: 'projects',
    title: 'cosmos.components.global-header.projects',
    icon: 'fa fa-folder',
    hideTitle: false,
    languageScope: LanguageScope.Cosmos,
    menuFactory: () =>
      // eslint-disable-next-line @nx/enforce-module-boundaries
      import(
        /* webpackPrefetch: true */
        /* webpackChunkName: 'esp-projects-feature-projects-menu' */ '@esp/projects/feature-projects-menu'
      ).then((m) => m.ProjectsMenuComponent as unknown as any),
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedMobileMenuPropertiesToOverride,
    }),
  };
};

const crmMenuItem = (
  overrides?: Partial<MobileMenuItemOverrideSettings>
): CosGlobalTabsNavigationItem => {
  return {
    id: 'crm',
    title: 'cosmos.components.global-header.crm',
    icon: 'fa fa-address-card',
    hideTitle: false,
    languageScope: LanguageScope.Cosmos,
    menuFactory: () =>
      // eslint-disable-next-line @nx/enforce-module-boundaries
      import(
        /* webpackPrefetch: true */
        /* webpackChunkName: 'esp-crm-ui-feature-crm-menu' */ '@esp/crm/feature-crm-menu'
      ).then(({ CrmMenuComponent }) => CrmMenuComponent),
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedMobileMenuPropertiesToOverride,
    }),
  };
};

export const availableMobileMenuItems: MobileMenuItemsKeyValue = {
  feedback: (overrides?: Partial<MobileMenuItemOverrideSettings>) =>
    feedbackMenuItem(overrides),
  collections: (overrides?: Partial<MobileMenuItemOverrideSettings>) =>
    collectionsMenuItem(overrides),
  projects: (overrides?: Partial<MobileMenuItemOverrideSettings>) =>
    projectsMenuItem(overrides),
  crm: (overrides?: Partial<MobileMenuItemOverrideSettings>) =>
    crmMenuItem(overrides),
};

export const constructMobileMenu = (menuItemsConfig: MobileMenuTypes[]) =>
  constructMenu({
    menuItemsConfig,
    availableMenuItems: availableMobileMenuItems,
  });
