import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

import { ThemesService } from '@cosmos/data-access-themes';
import { CosTheme } from '@cosmos/types-common';
import {
  WhiteLabelName,
  type WhiteLabelSettings,
} from '@esp/white-label/types';

@Injectable({ providedIn: 'root' })
export class WhiteLabelService {
  private readonly _document = inject(DOCUMENT);
  private readonly _themesService = inject(ThemesService);

  applyStyleAttributes(settings: WhiteLabelSettings): void {
    const styleElementId = 'white-label-vars' as const;

    // If there are no theme variables, remove the style element to avoid having the styles of a previous white label
    if (!settings.Theme?.Variables) {
      this._document.getElementById(styleElementId)?.remove();
      return;
    }

    const styleEl = this._document.createElement('style');
    styleEl.setAttribute('id', styleElementId);
    styleEl.innerHTML = `
      :root {
        ${
          settings.Theme?.Variables
            ? this._getThemeVariables(settings.Theme.Variables)
            : ''
        }
      }
    `;
    this._document.head.appendChild(styleEl);
  }

  applyWhiteLabelStyles(settings: WhiteLabelSettings): void {
    const { Global: globalTheme } = settings.Theme || {};
    this._applyGlobalTheme(globalTheme);
  }

  applyWhiteLabelFavicon(settings: WhiteLabelSettings): void {
    if (settings.WhiteLabelName !== WhiteLabelName.None) {
      const favIconEl = this._document.querySelector(
        'link[rel="icon"]'
      ) as HTMLLinkElement;

      if (favIconEl) {
        favIconEl.href = settings.FaviconUrl;
      }
    }
  }

  private _getThemeVariables(Variables: { [key: string]: string }) {
    return Object.keys(Variables).reduce((acc, key) => {
      return `${acc} --${key}: ${Variables[key]};`;
    }, '');
  }

  private _applyGlobalTheme(theme: CosTheme | undefined) {
    if (!theme) {
      // If there is no theme, apply the default theme
      this._themesService.applyDefaultTheme();
      return;
    }

    this._themesService.apply(theme);
  }
}
