import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  importProvidersFrom,
  inject,
  provideZoneChangeDetection,
  type ApplicationConfig,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';

import { provideThemes } from '@cosmos/data-access-themes';
import { CosTheme } from '@cosmos/types-common';
import { provideBrowserVersionDetector } from '@cosmos/util-browser-meta';
import { provideWebDownload } from '@cosmos/util-download';
import { provideI18nDateFormats } from '@cosmos/util-i18n-dates';
import { provideWebsocket } from '@cosmos/util-websocket';
import { enableMultipleSessionsAutoLogout } from '@esp/auth/data-access-auth';
import { enableIdleTracking } from '@esp/misc/feature-idle-detect';

import { environment } from '../environments/environment';

import { provideAppCosmos } from './cosmos.provider';
import { provideAppDataAccess } from './data-access.provider';
import { provideAppNgxs } from './ngxs.provider';
import { provideAppRouting } from './router.provider';
import { provideAppUi } from './ui.provider';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true, runCoalescing: true }),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.serviceWorkerEnabled,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideI18nDateFormats(),
    provideWebsocket(),
    provideWebDownload(),

    provideAnimations(),
    provideAppNgxs(),
    provideAppRouting(),

    provideHttpClient(withInterceptorsFromDi()),

    provideAppDataAccess(),
    provideAppCosmos(),
    provideAppUi(),

    environment.providers || [],
    importProvidersFrom(environment.imports || []),
    enableMultipleSessionsAutoLogout(),
    enableIdleTracking(),
    provideBrowserVersionDetector(() => {
      inject(Router).navigate(['/unsupported-browser']);
    }),

    provideThemes({ defaultTheme: CosTheme.ESP, appId: 'esp' }),
  ],
};
