<div class="icon">
  <i class="fa fa-building"></i>
</div>
<div class="label">
  <select
    class="cos-input cos-input--small w-100"
    [formControl]="selectedWhiteLabelNameControl"
  >
    @for (option of whiteLabelOptions | keyvalue; track option.key) {
      <option [value]="option.key">{{ option.value }}</option>
    }
  </select>
</div>
