import { WhiteLabelQueries } from 'esp/white-label/data-access';

import { Injectable } from '@angular/core';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs';

import {
  fromLocalState,
  fromObservable,
  fromSelector,
  LocalState,
} from '@cosmos/state';
import type { MenuItem } from '@cosmos/types-layout';
import { isMobileOrTablet$ } from '@cosmos/util-screen-size';
import { constructFooterMenu } from '@esp/misc/feature-menu-items';
import type { WhiteLabelSettings } from '@esp/white-label/types';

@Injectable()
export class GlobalFooterLocalState extends LocalState<GlobalFooterLocalState> {
  readonly isMobileOrTablet = fromObservable(isMobileOrTablet$());
  readonly whiteLabelSettings = fromSelector(WhiteLabelQueries.getSettings);
  readonly hasWhiteLabelName = fromSelector(
    WhiteLabelQueries.isWhiteLabelNameSet
  );

  readonly vm = fromLocalState<
    GlobalFooterLocalState,
    { navItems: MenuItem[]; logoUrl: string }
  >((state$) =>
    state$.pipe(
      map((state) => state.whiteLabelSettings),
      distinctUntilChanged(isEqual),
      map((settings: WhiteLabelSettings) => {
        if (!settings) {
          return { navItems: [], logoUrl: '' };
        }
        const footerMenuItems = constructFooterMenu(settings.FooterMenu || []);
        return {
          navItems: footerMenuItems,
          logoUrl: settings.LogoUrlColored ?? settings.LogoUrl,
        };
      })
    )
  );
}
