import { inject, Injectable, type OnDestroy } from '@angular/core';

import {
  COS_DATE_FORMATS,
  COS_DATE_LOCALE,
  DateAdapter,
} from '@cosmos/util-datetime';
import type { Dayjs } from '@cosmos/util-dayjs';
import {
  COS_DAYJS_DATE_ADAPTER_OPTIONS,
  DayjsDateAdapter,
} from '@cosmos/util-dayjs-adapter';
import { getShortDateFormat } from '@cosmos/util-i18n-values';
import { CosmosTranslocoService } from '@cosmos/util-translations';

export const PICK_FORMATS = {
  parse: {
    dateInput: ['l', 'L', 'll', 'LL'],
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function provideI18nDateFormats() {
  return [
    {
      provide: DateAdapter,
      useClass: PickDateAdapter,
      deps: [COS_DATE_LOCALE, COS_DAYJS_DATE_ADAPTER_OPTIONS],
    },
    { provide: COS_DATE_FORMATS, useValue: PICK_FORMATS },
    { provide: COS_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ];
}

@Injectable()
export class PickDateAdapter extends DayjsDateAdapter implements OnDestroy {
  private readonly _localeSubscription$ = inject(
    CosmosTranslocoService
  ).langChanges$.subscribe((locale) => this.setLocale(locale));

  override format(date: Dayjs, displayFormat: string): string {
    if (displayFormat === 'L') {
      return super.format(date, getShortDateFormat(this.locale, false));
    } else {
      return super.format(date, displayFormat);
    }
  }

  ngOnDestroy(): void {
    this._localeSubscription$.unsubscribe();
  }
}
