import {
  WhiteLabelActions,
  WhiteLabelQueries,
} from 'esp/white-label/data-access';

import { Injectable } from '@angular/core';

import { AuthQueries } from '@asi/auth/data-access-auth';
import { asDispatch, fromSelector, LocalState } from '@cosmos/state';

@Injectable()
export class WhiteLabelSelectLocalState extends LocalState<WhiteLabelSelectLocalState> {
  readonly selectedWhiteLabelName = fromSelector(
    WhiteLabelQueries.getWhiteLabelName
  );
  readonly selectWhiteLabelName = asDispatch(WhiteLabelActions.ApplySettings);
  readonly user = fromSelector(AuthQueries.getUser);
}
