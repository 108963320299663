import type { FeatureFlagsOptions } from '@cosmos/feature-flags';

import { releaseFlags } from './release-features';

export const featureFlagsToolbarOptions: FeatureFlagsOptions = {
  development: {
    title: 'Development',
    color: '#c23f22',
    flags: {
      post_mmp: true,
      stores: true,
      dashboard: false,
      feedBack: false,
      tbd: true,
      qwik: false,
      payment_providers: true,
      websitesMMP: true,
      custom_domains: true,
      custom_domains_sites: true,
      custom_domains_stores: false,
      inspire_seo_sites: true,
      inspire_seo_stores: true,
      inspire_search: true,
      inspire_presentation_description: true,
      inspire_orders_messages: true,
      white_label: true,
      custom_products: true,
      project_emails: true,
      order_lists_products: true,
      orders_table_event_type_filter: true,
      aggregate_orders: true,
      aggregated_po_margin: true,
      web_store_project_invoices: true,
      products_information_validation: true,
      website_payment_methods: true,
      vs_migration: true,
      vs_text_editor: true,
      web_store_projects: true,
      vs_retouch_tool: true,
      company_enrichments: true,
      sending_email_from: true,
      tenant_validations: true,
      confetti: true,
      aia_orders_integrations: true,
      super_user: true,
      crm_email_page: true,
      crm_global_notes_page: true,
      crm_global_tasks_page: true,
      pro_forma_templates: true,
      crm_prospects: true,
      order_detail_actions_usability_improvement: true,
      settings_third_party_system_name: true,
      settings_create_roles: true,
      crm_contacts_prospect: true,
    },
  },
  release: {
    title: 'Release',
    color: '#09755d',
    flags: releaseFlags,
  },
};
