import { createDialogDef } from '@cosmos/ui-dialog';

export const authDataDialogConfig = createDialogDef({
  load: async () =>
    (
      await import(
        /* webpackChunkName: 'auth-data-dialog' */ `./auth-data-dialog.component`
      )
    ).AuthDataDialogComponent,
  defaultConfig: {
    minWidth: '626px',
    minHeight: '0px',
  },
});
