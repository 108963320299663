import type { CosGlobalHeaderNavigationItem } from '@cosmos/types-layout';

import type { MenuItemsKeyValue, MenuTypes } from './menu.types';

export type HeaderMenuItemOverrideSettings = Pick<
  CosGlobalHeaderNavigationItem,
  | 'icon'
  | 'hideTitle'
  | 'featureFlags'
  | 'menuSettings'
  | 'url'
  | 'menuFactory'
  | 'title'
  | 'function'
  | 'externalUrl'
  | 'openInNewTab'
>;

export type HeaderMenuItemsKeyValue = MenuItemsKeyValue<
  HeaderMenuItemOverrideSettings,
  CosGlobalHeaderNavigationItem
>;
// export type HeaderMenuWithOverrides = MenuWithOverrides<HeaderMenuItemOverrideSettings>;
export type HeaderMenuTypes = MenuTypes<
  HeaderMenuItemOverrideSettings,
  CosGlobalHeaderNavigationItem
>;

export const allowedHeaderMenuPropertiesToOverride: (keyof HeaderMenuItemOverrideSettings)[] =
  [
    'icon',
    'hideTitle',
    'featureFlags',
    'menuSettings',
    'url',
    'menuFactory',
    'title',
    'externalUrl',
    'openInNewTab',
  ];
