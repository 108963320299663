import { COSMOS_CONFIG } from '@cosmos/core';
import type { ICosmosConfig } from '@cosmos/types-common';

export const provideCosmosLayout = (config: ICosmosConfig) => {
  return [
    {
      provide: COSMOS_CONFIG,
      useValue: config,
    },
  ];
};
