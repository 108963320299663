<cos-global-header #cosGlobalHeader>
  <cos-global-header-logo>
    <a class="logo header-style-18" routerLink="/home">
      <esp-encore-logo
        [title]="state.whiteLabelSettings?.AppName"
        type="white"
        [customLogoUrl]="state.whiteLabelSettings | whiteLabelLogo"
      />
    </a>
  </cos-global-header-logo>

  @if (showGlobalSearch() && showDesktopSearch()) {
    <cos-global-header-center class="2xl:max-w-[668px]">
      <ng-container *ngTemplateOutlet="searchContent" />
    </cos-global-header-center>
  }

  @if (showGlobalSearch() && !showDesktopSearch()) {
    <cos-global-header-secondary>
      <ng-container *ngTemplateOutlet="searchContent" />
    </cos-global-header-secondary>
  }

  <cos-global-header-nav [navigation]="headerNavItems()" />
</cos-global-header>

<cos-global-tabs class="md:!hidden" [navigation]="mobileNavitems()" />

<ng-template #searchContent>
  <esp-global-search [showInspireButton]="true" />
</ng-template>
