/* eslint-disable no-restricted-globals */
import { makeEnvironmentProviders } from '@angular/core';
import {
  defaultModules,
  QUILL_CONFIG_TOKEN,
  type QuillConfig,
} from 'ngx-quill/config';
import { defer, EMPTY, firstValueFrom, shareReplay } from 'rxjs';

const id = 'quill';
const quillCSS$ = defer(() => {
  if (global_isServer) {
    return EMPTY;
  }

  // eslint-disable-next-line @nx/enforce-module-boundaries
  return import(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    /* webpackIgnore: true */ '/assets/js/quill/quill-styles.min.js'
  ).then(({ quillCore, quillBubble, quillSnow }) => {
    if (global_isServer) {
      return;
    }

    const shouldAppend = document.head.querySelector(`[id=${id}]`) === null;

    if (shouldAppend) {
      for (const styles of [quillCore, quillBubble, quillSnow]) {
        const style = document.createElement('style');
        style.id = id;
        style.appendChild(document.createTextNode(styles));
        document.head.appendChild(style);
      }
    }
  });
}).pipe(shareReplay({ bufferSize: 1, refCount: true }));

const config: QuillConfig = {
  modules: defaultModules,
  beforeRender: () => firstValueFrom(quillCSS$),
};

export function provideQuillConfig() {
  return makeEnvironmentProviders([
    { provide: QUILL_CONFIG_TOKEN, useValue: config },
  ]);
}
