<div class="icon">
  <i class="fab fa-github"></i>
</div>
<div class="label">
  <div class="gap-2 flex-cols-3 flex">
    <span
      ><a href="https://github.com{{ gitInformation.url }}" target="_github">{{
        gitSha?.substring(0, 7)
      }}</a></span
    >
    <span
      ><a
        href="https://github.com{{ gitInformation.url }}"
        target="_github"
        title="{{ buildDate | cosDate: 'medium' : 'UTC' }}"
        >{{ buildDate | dateAgo }}</a
      ></span
    >
    <span>{{ gitBranch }}</span>
  </div>
</div>
