import type {
  FooterMenuItemsKeyValue,
  FooterMenuTypes,
} from '@esp/misc/types-menu-items';

import { constructMenu } from './utils';

export const availableFooterMenuItems: FooterMenuItemsKeyValue = {};

export const constructFooterMenu = (menuItemsConfig: FooterMenuTypes[]) =>
  constructMenu({
    menuItemsConfig,
    availableMenuItems: availableFooterMenuItems,
  });
