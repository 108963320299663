import type { CosGlobalTabsNavigationItem } from '@cosmos/types-layout';

import type { MenuItemsKeyValue, MenuTypes } from './menu.types';

export type MobileMenuItemOverrideSettings = Pick<
  CosGlobalTabsNavigationItem,
  | 'icon'
  | 'hideTitle'
  | 'featureFlags'
  | 'menuSettings'
  | 'url'
  | 'menuFactory'
  | 'title'
  | 'function'
>;

export type MobileMenuItemsKeyValue = MenuItemsKeyValue<
  MobileMenuItemOverrideSettings,
  CosGlobalTabsNavigationItem
>;

export type MobileMenuTypes = MenuTypes<
  MobileMenuItemOverrideSettings,
  CosGlobalTabsNavigationItem
>;

export const allowedMobileMenuPropertiesToOverride: (keyof MobileMenuItemOverrideSettings)[] =
  [
    'icon',
    'hideTitle',
    'featureFlags',
    'menuSettings',
    'url',
    'menuFactory',
    'title',
  ];
