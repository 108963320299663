import { importProvidersFrom } from '@angular/core';
import type { HashMap } from '@ngneat/transloco';
import { map, Observable } from 'rxjs';

import { CosDialogModule } from '@cosmos/components/dialog';
import {
  CosFormFieldModule,
  type ErrorsMap,
} from '@cosmos/components/form-field';
import { CosMenuModule } from '@cosmos/components/menu';
import { AppSettingsModule } from '@cosmos/data-access-app-settings';
import { provideCosmosLayout } from '@cosmos/layout';
import { provideMeta, type MetaSettings } from '@cosmos/meta';
import { provideNgEventPlugins } from '@cosmos/ng-event-plugins';
import { provideQuillConfig } from '@cosmos/quill-config';
import { CosmosScrollPositionRestorationModule } from '@cosmos/scroll-position-restoration';
import { LAYOUT_CONFIG } from '@cosmos/types-layout';
import { COS_UI_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@cosmos/ui-autocomplete';
import { COS_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@cosmos/ui-datepicker';
import { CosNotificationModule } from '@cosmos/ui-notification';
import { COS_SELECT_SCROLL_STRATEGY_PROVIDER } from '@cosmos/ui-select';
import { COS_DATE_LOCALE } from '@cosmos/util-datetime';
import { provideWebDownload } from '@cosmos/util-download';
import {
  CosmosTranslocoService,
  LanguageScope,
} from '@cosmos/util-translations';
import type { AppConfig } from '@esp/auth/types';
import {
  DESKTOP_HEADER_NAV_HEIGHT,
  MOBILE_HEADER_NAV_HEIGHT,
} from '@esp/layouts/ui-encore-layout';

const getKey = (
  translocoService: CosmosTranslocoService,
  key: string,
  params?: HashMap
) =>
  translocoService.translate(
    `cosmos.components.form-field.encore-errors.${key}`,
    { ...params }
  );

export const formFieldErrorsConfig = (
  translocoService: CosmosTranslocoService
): Observable<ErrorsMap> => {
  return translocoService.getLangChanges$([LanguageScope.Cosmos]).pipe(
    map(() => {
      return {
        email: getKey(translocoService, 'email'),
        max: ({ max, actualValue, label }) =>
          label
            ? getKey(translocoService, 'max-with-label', { label, max })
            : getKey(translocoService, 'max-no-label', { max }),
        maxlength: ({ requiredLength, actualLength, label }) =>
          label
            ? getKey(translocoService, 'max-length-with-label', {
                label,
                requiredLength,
              })
            : getKey(translocoService, 'max-length-no-label', {
                requiredLength,
              }),
        minlength: ({ requiredLength, actualLength, label }) =>
          label
            ? getKey(translocoService, 'min-length-with-label', {
                label,
                requiredLength,
              })
            : getKey(translocoService, 'min-length-no-label', {
                requiredLength,
              }),
        min: ({ min, actualValue, label }) =>
          label
            ? getKey(translocoService, 'min-with-label', { label, min })
            : getKey(translocoService, 'min-no-label', { min }),
        required: getKey(translocoService, 'required'), // 'This field is required.',
        serverError: (error) => error,
      };
    })
  );
};

const COSMOS_LAYOUT_CONFIG: AppConfig = {
  colorTheme: 'theme-default',
  layoutMode: 'fullwidth',
  customScrollbars: false,
  routerAnimation: 'fadeIn',
  layout: {
    footer: {
      position: 'below-static',
      cssClass: '',
    },
    navbar: {
      position: 'top',
      cssClass: 'cosmos-navy-50',
      header: {
        cssClass: 'cosmos-navy-900',
      },
      openBehavior: 'push',
    },
    toolbar: {
      position: 'below-fixed',
    },
  },
};

const COSMOS_META_CONFIG: MetaSettings = {
  applicationName: 'ESP+',
  pageTitleSeparator: ' - ',
  defaults: {
    title: 'ESP+',
  },
};

export const provideAppCosmos = () => [
  provideNgEventPlugins(),
  provideCosmosLayout(COSMOS_LAYOUT_CONFIG),
  importProvidersFrom([
    CosDialogModule,
    CosMenuModule,
    CosFormFieldModule.forRoot(formFieldErrorsConfig),
    CosmosScrollPositionRestorationModule,
    AppSettingsModule,
    CosNotificationModule,
  ]),
  provideQuillConfig(),
  provideWebDownload(),
  provideMeta(COSMOS_META_CONFIG),
  { provide: COS_DATE_LOCALE, useValue: 'en-US' },
  COS_SELECT_SCROLL_STRATEGY_PROVIDER,
  COS_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
  COS_UI_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
  {
    provide: LAYOUT_CONFIG,
    useValue: {
      desktopHeaderNavHeight: DESKTOP_HEADER_NAV_HEIGHT,
      mobileHeaderNavHeight: MOBILE_HEADER_NAV_HEIGHT,
    },
  },
];
