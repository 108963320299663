import { NgZone, type Injector } from '@angular/core';

// TODO(ds): imports between cosmos/config and cosmos/analytics should be refactored
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ConfigService } from '@cosmos/config';

import type { HeapIoConfig } from './types';

export * from './types';
export function startHeapIo(injector: Injector): void {
  if (global_isServer || (ngDevMode && typeof jest !== 'undefined')) {
    return;
  }

  const ngZone = injector.get(NgZone);
  const config: HeapIoConfig = injector.get(ConfigService).get('heapIo');

  if (config.enabled) {
    ngZone.runOutsideAngular(() => {
      import(/* webpackChunkName: 'cosmos-analytics' */ './heap-io').then((m) =>
        injector.get(m.HeapIoService).start(config)
      );
    });
  }
}
