import { KeyValuePipe } from '@angular/common';
import {
  APP_INITIALIZER,
  ChangeDetectionStrategy,
  Component,
  inject,
  makeEnvironmentProviders,
  type OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { tap } from 'rxjs';

import { CosSlideToggleComponent } from '@cosmos/components/toggle';
import { CosFeatureFlagsToolbarControlsSet } from '@cosmos/feature-flags-toolbar';
import { FormControl } from '@cosmos/forms';
import { useLocalState } from '@cosmos/state';
import { injectDestroyRef } from '@cosmos/util-common';

import { DocumentStyleSelectLocalState } from './document-style-select.local-state';

/*
  This component is responsible for enabling or disabling the new style of order documents.
  The selection is persisted in local storage, so it only affects the current user.
  It works in conjunction with the OrderDocumentsNewStyleInterceptor interceptor.
 */
@Component({
  selector: 'esp-document-style-select',
  templateUrl: './document-style-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [KeyValuePipe, ReactiveFormsModule, CosSlideToggleComponent],
  providers: [DocumentStyleSelectLocalState],
})
export class EspDocumentStyleSelectComponent implements OnInit {
  protected readonly documentsNewStyleEnabled = new FormControl<boolean>(false);
  readonly state = useLocalState(DocumentStyleSelectLocalState, this);
  private readonly _destroyRef = injectDestroyRef();

  ngOnInit(): void {
    this.documentsNewStyleEnabled.setValue(
      this.state.documentsNewStyleEnabled,
      { emitEvent: false }
    );
    this.documentsNewStyleEnabled.valueChanges
      .pipe(
        tap((value) => (this.state.documentsNewStyleEnabled = value)),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();
  }
}

export function withDocumentStyleSelect() {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        const controls = inject(CosFeatureFlagsToolbarControlsSet);
        return () => controls.add(EspDocumentStyleSelectComponent);
      },
    },
  ]);
}
