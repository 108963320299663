import { Injectable } from '@angular/core';
import { Action, State, type StateContext } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';

import { ToastActions } from '@cosmos/types-notification-and-toast';
import { CompaniesService } from '@esp/companies/data-access-api';
import type { CompanyFormModel } from '@esp/companies/types-company-forms';
import { ContactsService } from '@esp/contacts/data-access-api';
import { CrmCompanyQuickBooksActions } from '@esp/crm/data-access-company-quickbooks';
import type { Company, Contact } from '@esp/parties/types';
import type {
  Project,
  ProjectCreateWithNewCustomer,
  ProjectDetailsForm,
} from '@esp/projects/types';
import { CompanyRole } from '@esp/quickbooks/types';

import {
  ProjectActions,
  ProjectCreateWithNewCustomerActions,
} from '../actions';

import { TOAST_MESSAGES } from './toast-messages';

export interface ProjectCreateWithNewCustomerStateModel {
  creatingCustomer: boolean;
  creatingContact: boolean;
  creatingProject: boolean;
}

type ThisStateModel = ProjectCreateWithNewCustomerStateModel;
type ThisStateContext = StateContext<ThisStateModel>;

@State<ProjectCreateWithNewCustomerStateModel>({
  name: 'projectCreateWithNewCustomer',
  defaults: {
    creatingCustomer: false,
    creatingContact: false,
    creatingProject: false,
  },
})
@Injectable()
export class ProjectCreateWithNewCustomerState {
  constructor(
    private readonly _companiesService: CompaniesService,
    private readonly _contactsService: ContactsService
  ) {}

  @Action(ProjectCreateWithNewCustomerActions.CreateProjectWithNewCustomer)
  async createProjectWithNewCustomer(
    ctx: ThisStateContext,
    {
      payload,
    }: ProjectCreateWithNewCustomerActions.CreateProjectWithNewCustomer
  ) {
    payload = this._filterPayloadEmptyFields(payload);

    // CREATE CUSTOMER
    const customer = await this._createCustomer(
      ctx,
      this._mapProjectCreateCustomerFormToCompany(payload.customer!)
    );

    if (payload.customer.CompanyInformation.CreateQBCompany) {
      ctx.dispatch(
        new CrmCompanyQuickBooksActions.SaveCompanyToQuickBooks(
          customer.Id,
          customer.Name,
          CompanyRole.Customer
        )
      );
    }

    // CREATE CONTACT
    if (
      payload.customer!.CompanyInformation.GivenName &&
      payload.customer!.CompanyInformation.FamilyName
    ) {
      await this._createContact(
        ctx,
        this._mapProjectCreateCustomerFormToContact(
          payload.customer!,
          customer.Id
        ),
        customer,
        payload.customer!
      );
    }

    // CREATE PROJECT
    await this._createProject(
      ctx,
      this._mapProjectDetailsFormToProject(payload.project, customer),
      payload.productIds,
      payload.redirectAfterCreation
    );
  }

  @Action(ProjectCreateWithNewCustomerActions.CreateProjectWithoutNewCustomer)
  async createProjectWithoutNewCustomer(
    ctx: ThisStateContext,
    {
      payload,
    }: ProjectCreateWithNewCustomerActions.CreateProjectWithoutNewCustomer
  ) {
    await this._createProject(
      ctx,
      this._mapProjectDetailsFormToProject(payload.project, {
        Id: payload.customerId,
      } as Company),
      payload.productIds,
      payload.redirectAfterCreation
    );
  }

  private async _createCustomer(
    ctx: ThisStateContext,
    payload: Company
  ): Promise<Company> {
    ctx.patchState({
      creatingCustomer: true,
    });

    try {
      return await firstValueFrom(this._performCreateCustomerRequest(payload));
    } catch (e) {
      ctx.dispatch(new ToastActions.Show(TOAST_MESSAGES.COMPANY_NOT_CREATED()));

      throw e;
    } finally {
      ctx.patchState({
        creatingCustomer: false,
      });
    }
  }

  private _performCreateCustomerRequest(payload: Company): Observable<Company> {
    return this._companiesService.create(payload, {
      params: { ignoreConflict: 'true' },
    });
  }

  private async _createProject(
    ctx: ThisStateContext,
    payload: Project,
    productIds: number[],
    redirect?: boolean
  ): Promise<void> {
    ctx.patchState({ creatingProject: true });

    try {
      await firstValueFrom(
        ctx.dispatch(
          new ProjectActions.CreateProject(payload, productIds, redirect)
        )
      );
    } finally {
      ctx.patchState({ creatingProject: false });
    }
  }

  private async _createContact(
    ctx: ThisStateContext,
    payload: Contact,
    customer: Company,
    formValue: CompanyFormModel
  ): Promise<Contact> {
    ctx.patchState({
      creatingContact: true,
    });

    try {
      const contact = await firstValueFrom(
        this._performCreateContactRequest(payload)
      );

      await firstValueFrom(
        this._assignContactToCustomer(customer, contact, formValue)
      );

      return contact;
    } catch (e) {
      ctx.dispatch(new ToastActions.Show(TOAST_MESSAGES.CONTACT_NOT_CREATED()));

      throw e;
    } finally {
      ctx.patchState({
        creatingContact: false,
      });
    }
  }

  private _performCreateContactRequest(contact: Contact): Observable<Contact> {
    return this._contactsService.create(contact);
  }

  private _assignContactToCustomer(
    customer: Company,
    contact: Contact,
    payload: CompanyFormModel
  ): Observable<Partial<Company>> {
    return this._companiesService.save({
      ...customer,
      Links: [
        {
          Id: contact.Id,
          Comment: 'Primary Contact',
          Title: 'Primary Contact',
          Type: {
            Id: 1,
            Code: 'CONTACT',
            Forward: 'is a contact of',
            ForwardTitle: 'contact',
            Reverse: 'is the employer of',
            ReverseTitle: 'employer',
            ForPerson: true,
            ForCompany: true,
            IsEditable: true,
          },
          IsEditable: true,
        },
      ],
      BillingContact: payload.CompanyInformation.IsBillingContact
        ? contact
        : customer.BillingContact,
      ShippingContact: payload.CompanyInformation.IsShippingContact
        ? contact
        : customer.ShippingContact,
      AcknowledgementContact: payload.CompanyInformation
        .IsAcknowledgementContact
        ? contact
        : customer.AcknowledgementContact,
    });
  }

  private _mapProjectCreateCustomerFormToCompany(
    payload: CompanyFormModel
  ): Company {
    return {
      ...payload.CompanyInformation,
      ...payload.BrandInformation,
      Emails: payload.CompanyInformation.PrimaryEmail
        ? [payload.CompanyInformation.PrimaryEmail]
        : [],
      Phones: payload.CompanyInformation.Phone
        ? [payload.CompanyInformation.Phone]
        : [],
      Addresses: [
        {
          ...payload.CompanyInformation.Address,
          Name: payload.CompanyInformation.Name,
        },
      ],
      Websites: payload.BrandInformation.Website
        ? [payload.BrandInformation.Website]
        : [],
      PrimaryBrandColor: payload.BrandInformation.PrimaryBrandColor,
      IconMediaLink: payload.BrandInformation.IconMediaLink,
      LogoMediaLink: payload.BrandInformation.LogoMediaLink,
      Industry: payload.BrandInformation.Industry,
      YearFounded: payload.BrandInformation.YearFounded,
      EmployeesCount: payload.BrandInformation.EmployeesCount,
    } as Company;
  }

  private _mapProjectCreateCustomerFormToContact(
    payload: CompanyFormModel,
    companyId: number
  ): Contact {
    return {
      GivenName: payload.CompanyInformation.GivenName,
      FamilyName: payload.CompanyInformation.FamilyName,
      Links: [
        {
          Comment: 'Primary Contact',
          Title: 'Primary Contact',
          Type: {
            Id: 1,
            Code: 'CONTACT',
            Forward: 'is a contact of',
            ForwardTitle: 'contact',
            Reverse: 'is the employer of',
            ReverseTitle: 'employer',
            ForPerson: true,
            ForCompany: true,
            IsEditable: true,
          },
          To: {
            Id: companyId,
          },
          IsEditable: true,
          Reverse: true,
        },
      ],
      Emails: payload.CompanyInformation.PrimaryEmail
        ? [payload.CompanyInformation.PrimaryEmail]
        : [],
      Phones: payload.CompanyInformation.Phone
        ? [payload.CompanyInformation.Phone]
        : [],
      Addresses: [
        {
          ...payload.CompanyInformation.Address,
          Name: `${payload.CompanyInformation.GivenName} ${payload.CompanyInformation.FamilyName}`,
        },
      ],
      Websites: payload.BrandInformation.Website
        ? [payload.BrandInformation.Website]
        : [],
    } as Contact;
  }

  private _mapProjectDetailsFormToProject(
    payload: ProjectDetailsForm,
    customer: Company
  ): Project {
    return {
      ...payload,
      InHandsDate: payload.InHandsDate as Date,
      EventDate: payload.EventDate as Date,
      Customer: customer,
      Budget: Number(payload.Budget.replace(/,/g, '')),
      NumberOfAssignees: Number(payload.NumberOfAssignees.replace(/,/g, '')),
    };
  }

  private _filterPayloadEmptyFields(
    payload: ProjectCreateWithNewCustomer
  ): ProjectCreateWithNewCustomer {
    payload.customer = {
      CompanyInformation: {
        ...payload.customer!.CompanyInformation,
        PrimaryEmail: payload.customer!.CompanyInformation.PrimaryEmail?.Address
          .length
          ? payload.customer!.CompanyInformation.PrimaryEmail
          : undefined,
        Phone: payload.customer!.CompanyInformation.Phone?.Number
          ? payload.customer!.CompanyInformation.Phone
          : undefined,
      },
      BrandInformation: {
        ...payload.customer!.BrandInformation,
        Website: payload.customer!.BrandInformation.Website?.Url
          ? payload.customer!.BrandInformation.Website
          : undefined,
      },
    };

    return payload;
  }
}
