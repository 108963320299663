import type { Provider } from '@angular/core';
import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';

import { SilentKeyEventPlugin } from './silent-key.plugin';
import { SilentEventPlugin } from './silent.plugin';

export function provideNgEventPlugins(): Provider[] {
  return [
    {
      provide: EVENT_MANAGER_PLUGINS,
      useClass: SilentEventPlugin,
      multi: true,
    },
    {
      provide: EVENT_MANAGER_PLUGINS,
      useClass: SilentKeyEventPlugin,
      multi: true,
    },
  ];
}
