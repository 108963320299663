import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { PurchaseOrdersSearchState } from '@esp/orders/data-access-purchase-order-search';

import { ProjectItemsAvailabilityState, ProjectItemsState } from './states';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      ProjectItemsState,
      ProjectItemsAvailabilityState,
      PurchaseOrdersSearchState,
    ]),
  ],
})
export class EspProjectsDataAccessProjectItemsModule {}
