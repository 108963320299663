import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CosScrollPositionRestoration } from './services';

@NgModule({
  imports: [CommonModule],
})
export class CosmosScrollPositionRestorationModule {
  constructor(private readonly _scrollPosition: CosScrollPositionRestoration) {}
}
