export const releaseFlags = {
  post_mmp: false,
  stores: false,
  dashboard: false,
  feedBack: false,
  tbd: false,
  qwik: false,
  payment_providers: true,
  websitesMMP: false,
  custom_domains: false,
  custom_domains_sites: true,
  custom_domains_stores: false,
  inspire_seo_sites: true,
  inspire_seo_stores: false,
  inspire_search: true,
  inspire_presentation_description: true,
  inspire_orders_messages: true,
  white_label: true,
  custom_products: false,
  project_emails: true,
  order_lists_products: true,
  orders_table_event_type_filter: false,
  aggregate_orders: true,
  aggregated_po_margin: false,
  products_information_validation: false,
  website_payment_methods: false,
  vs_migration: true,
  vs_text_editor: false,
  web_store_projects: true,
  vs_retouch_tool: false,
  company_enrichments: true,
  web_store_project_invoices: false,
  sending_email_from: false,
  tenant_validations: true,
  confetti: true,
  aia_orders_integrations: true,
  super_user: false,
  crm_email_page: true,
  crm_global_notes_page: false,
  crm_global_tasks_page: true,
  pro_forma_templates: true,
  crm_prospects: true,
  order_detail_actions_usability_improvement: true,
  settings_third_party_system_name: true,
  settings_create_roles: false,
  crm_contacts_prospect: true,
};
