import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { NgxsAfterSuccessModule } from '@cosmos/state';

import {
  CompaniesArtworksSearchState,
  CompaniesArtworksState,
  CompaniesDecorationsState,
  CompaniesDesignsSearchState,
  CompaniesDesignsState,
  CompaniesLinksState,
  CompaniesRecentState,
  CompaniesSearchState,
  CompaniesState,
  CustomersRecentState,
} from './states';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      CompaniesState,
      CompaniesSearchState,
      CompaniesRecentState,
      CustomersRecentState,
      CompaniesLinksState,
      CompaniesDecorationsState,

      CompaniesArtworksState,
      CompaniesArtworksSearchState,

      CompaniesDesignsState,
      CompaniesDesignsSearchState,
    ]),
    NgxsAfterSuccessModule,
  ],
})
export class EspCompaniesDataAccessCompaniesModule {}
