import type {
  ToastData,
  ToastType,
} from '@cosmos/types-notification-and-toast';
import { LanguageScope } from '@cosmos/util-translations';

const getTitleKey = (key: string) => `espCompanies.toast-messages.${key}.title`;
const getBodyKey = (key: string) => `espCompanies.toast-messages.${key}.body`;

const getToastData = (
  key: string,
  type: ToastType,
  interpolationParams?: Record<string, unknown>
): ToastData => {
  const data = {
    title: getTitleKey(key),
    body: getBodyKey(key),
    type,
    languageScope: LanguageScope.EspCompanies,
  };

  return interpolationParams ? { ...data, interpolationParams } : data;
};

export const TOAST_MESSAGES = {
  DESIGN_DELETED: (): ToastData => getToastData('design-deleted', 'confirm'),
  DESIGN_NOT_DELETED: (): ToastData =>
    getToastData('design-not-deleted', 'error'),
};
