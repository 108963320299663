import { APP_INITIALIZER, inject } from '@angular/core';

import { ThemesService } from './themes.service';
import { COS_THEMES_CONFIG, type CosThemesConfig } from './themes.types';

export const provideThemes = (
  initialConfig: Omit<CosThemesConfig, 'lsKey'>
) => [
  {
    provide: COS_THEMES_CONFIG,
    useValue: { ...initialConfig, lsKey: `${initialConfig.appId}:theme` },
  },
  ThemesService,
  {
    provide: APP_INITIALIZER,
    useFactory: () => {
      const service = inject(ThemesService);

      return async () => await service.init();
    },
    multi: true,
  },
];
