<div class="icon">
  <i class="fa fa-globe"></i>
</div>
<div class="label">
  <cos-toggle
    labelPosition="after"
    size="sm"
    [checked]="showTranslations"
    (change)="toggleTranslations($event.checked)"
    >Show Translations
  </cos-toggle>
</div>
