import {
  APP_BOOTSTRAP_LISTENER,
  APP_INITIALIZER,
  ApplicationRef,
  inject,
  makeEnvironmentProviders,
  NgZone,
  type EnvironmentProviders,
} from '@angular/core';

import {
  ExplicitFeatureFlagsService,
  FEATURE_FLAGS_OPTIONS,
  type FeatureFlagsOptions,
  type FeatureFlagsSet,
} from '@cosmos/feature-flags';
import { LCP$ } from '@cosmos/tick-scheduler';

export function provideFeatureFlagsToolbarInitializer(
  options: FeatureFlagsOptions,
  ...providers: EnvironmentProviders[]
) {
  return makeEnvironmentProviders([
    ...providers,
    { provide: FEATURE_FLAGS_OPTIONS, useValue: options },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const featureFlagService = inject(ExplicitFeatureFlagsService);

        return () => {
          const featureFlag = featureFlagService.selectedFeatureFlagName;
          const option: FeatureFlagsSet | undefined = options?.[featureFlag];

          if (option) {
            featureFlagService.setFlags(option.flags);
          }
        };
      },
      multi: true,
    },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: () => {
        if (global_isServer) {
          return () => {};
        }

        if (global_isRealProduction) {
          throw new Error('Should not be provided at the actual production.');
        }

        const appRef = inject(ApplicationRef);
        const lcp$ = inject(LCP$);
        const ngZone = inject(NgZone);

        return () => {
          // Wait for the app to fully bootstrap to avoid conflicts
          // with other frame drops in the performance tab.
          lcp$.subscribe(() => {
            import('./feature-flags-toolbar.component').then((m) => {
              ngZone.run(() => m.renderFeatureFlagsToolbar(appRef));
            });
          });
        };
      },
    },
  ]);
}
