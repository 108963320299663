import { ChangeDetectionStrategy, Component } from '@angular/core';

import { useLocalState } from '@cosmos/state';
import { FooterComponent } from '@esp/misc/ui-footer';

import { ColumnLayoutFooterComponent } from './components/column-layout-footer/column-layout-footer.component';
import { GlobalFooterLocalState } from './global-footer.local-state';

@Component({
  selector: 'esp-feature-global-footer',
  templateUrl: './feature-global-footer.component.html',
  styleUrl: './feature-global-footer.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ColumnLayoutFooterComponent, FooterComponent],
  providers: [GlobalFooterLocalState],
})
export class FeatureGlobalFooterComponent {
  readonly state = useLocalState(GlobalFooterLocalState, this);
}
